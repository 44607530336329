.tr-rejection-cause-modal-overlay{
    position: fixed;
    top : 0;
    left : 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.3);
    z-index: 100001;
}

.tr-rejection-modal-wrapper{
    position: absolute;
    box-sizing: border-box;
    width: 60%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1001;
    font-family: "Montserrat";
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
    border: 1px solid black;
}

.tr-rejection-modal-header{
    position: relative;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-left: 10px solid rgb(4,173,168);
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
}

.tr-rejection-modal-title-wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-top: 15px;
}

.tr-rejection-modal-heading{
    font-size: 21px;
    font-weight: 600;
    color : #1A3552;
    margin-right: 30px;
}

.tr-rejection-modal-subheading{
    font-size: 15px;
    font-weight: 500;
    color : #1A3552;
    margin-right: 30px;
}

.tr-rejection-modal-close {
    position: absolute;
    top : 15px;
    right : 15px;
    font-size: 24px;
    color : #656565;
}

.tr-rejection-modal-body {
    position: relative;
    background-color: #F1F6F7;
    padding: 3%;
    width: 100%;
    box-sizing: border-box;
}

.tr-rejection-modal-help-text{
    border: 1px solid #555;
    color : #444;
    text-align: justify;
    padding: 8px;
    width: 100%;
    background-color: #fff;
    font-size: 14px;
    box-sizing: border-box;
}

.tr-rejection-modal-label{
    color : #222;
    text-align: justify;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;
}

.tr-rejection-modal-cause-textarea{
    color : #222;
    text-align: justify;
    padding: 8px;
    width: 100%;
    font-size: 14px;
    resize: none;
    overflow: hidden;
    min-height: 50px;
    max-height: 200px;
    box-sizing: border-box;
    margin-top: 15px;
    scroll-behavior : smooth;
    overflow-y : auto;
    font-family: 'Montserrat';
    outline: none;
}

.tr-rejection-modal-table-container{
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    margin-top: 15px;
}

.tr-rejection-modal-confirmation-text-container{
    width: 100%;
    box-sizing: border-box;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 20px;
}

.tr-rejection-modal-confirmation-checkbox{
    box-sizing: border-box;
    font-size: 18px;
}

.tr-rejection-modal-confirmation-text{
    box-sizing: border-box;
    display: inline-block;
    color : #444;
    font-size: 14px;
    text-align: justify;
}

.tr-rejection-modal-footer{
    position: relative;
    background-color: #fff;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
}

.tr-rejection-modal-footer-button-container {
    display: flex;
    justify-content: flex-end;
    column-gap: 15px;
    align-items: center;
}

.tr-rejection-modal-footer-button-subcontainer {
    display: flex;
    justify-content: flex-end;
    column-gap: 3px;
    align-items: center;
}

.tr-rejection-modal-footer-button {
    padding : 5px 20px 5px 20px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
}

.tr-rejection-modal-footer-inactive-button {
    padding : 5px 20px 5px 20px;
    border-radius: 5px;
    font-size: 15px;
    cursor: not-allowed;
    background-color: #ddd;
    color : rgb(255, 255, 255);
    font-family: "Montserrat", sans-serif;
    border: 1px solid #ddd;
}

.tr-rejection-save-button {
    background-color: #00BCB8;
    color : white;
    font-family: "Montserrat", sans-serif;
    border: 1px solid #00BCB8;
}

.tr-rejection-reject-button {
    background-color: #203864;
    color : white;
    font-family: "Montserrat", sans-serif;
    border: 1px solid #203864;
}

.tr-rejection-close-button {
    background-color: #fff;
    color : #333;
    font-family: "Montserrat", sans-serif;
    border : 1px solid #bbb;
}