.report-link-wrapper{
    display: inline-block;
    margin-left : 8px;
}

.report-link{
    display: flex;
    align-items: center;
    text-decoration: none;
}

.report-link-text{
    text-decoration: none;
    font-weight: bold;
    font-style: italic;
    color : rgb(43, 120, 236);
    font-size: 14px;
}

.report-link-icon{
    margin-left: 6px;
    filter: invert(20%) sepia(84%) saturate(4841%) hue-rotate(341deg) brightness(108%) contrast(113%);
}