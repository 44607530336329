.inactive-overlay{
    position:absolute;
    top : 0;
    bottom: 0;
    width: 98%;
    height: 100%;
    background-color: rgba(255,255,255,0.5);
    z-index: 600;
}

.error-bar{
    background-color: #f6dbdb;
    color: #de8d8e;
    font-weight: 600;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.error-msg-div{
    font-size: 16px;
    display: inline;
}

.error-bar-close{
    font-size: "16px"; 
    cursor: "pointer";
    display: inline;
}

.container{
    width: 98%;
    margin : auto;
    margin-top : 15px;
    border: 1px solid #eee;
    border-radius: 3px 3px 0px 0px;
    box-sizing: border-box;
}

.header-container{
    box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    padding: 5px 10px 10px 10px;
    border-radius: 3px 3px 0px 0px;
}

.header-text-container{
    padding: 10px 0px 0px 10px;
    font-weight: 600;
    font-size: 14px;
    color : #555;
}

.pill-bar{
    box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    padding: 5px 10px 10px 10px;
    border-radius: 3px 3px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.pill-container{
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    padding: 5px 0px 5px 0px;
}

.pill{
    position: relative;
    min-width: 150px;
    border-radius: 15px;
    border: 1px solid #1E3E62;
    font-size: 13px;
    cursor: pointer;
}

.pill-active{
    background-color: #1E3E62;
    color : #ffffff;
    
}

.pill-inactive{
    background-color: #EEF2F7;
    color: #1E3E62;
}

.pill-text-container{
    width: 100%;
    padding: 5px;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
}
.pill-count-container{
    position: absolute;
    right : 5px;
    top : 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.pill-tick-container{
    position: absolute;
    left : 5px;
    top : 50%;
    transform: translateY(-40%);
    cursor: pointer;
    font-size: 11px;
}

.main-ellipsis {
    width: 15px;
    text-align: center;
    font-size: 16px;
    position: relative;
    cursor: pointer;
}

.main-context-menu{
    position: absolute;
    top : 10px;
    right: 10px;
    background-color: #fff;
    padding: 5px;
    box-shadow: 0px 0px 2px #555;
    z-index: 100;
}
.main-context-menu-item{
    font-size: 14px;
    padding: 3px 8px 3px 8px;
    cursor: pointer;
}

.main-context-menu-item:hover {
    background-color: #eeeeee;
}

.option-bar{
    box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    padding: 0px 10px 10px 15px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 25px;
}

.table-container{
    position: relative;
}

.multi-select-option-link{
    font-style: italic;
    color : #127AC4;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
}

.select-all-container{
    position : absolute;
    top : 55px;
    left : 11px;
    cursor: pointer;
    z-index: 500;
}

.option-bar-bottom{
    box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    padding: 15px 10px 15px 15px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 35px;
}