
.tr-report-view-modal-wrapper{
    position: absolute;
    box-sizing: border-box;
    width: 90%;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1001;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
}

.tr-report-view-modal-header{
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-left: 10px solid rgb(4,173,168);
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
}

.tr-report-view-modal-title-wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-top: 15px;
}

.tr-report-view-modal-heading{
    font-size: 21px;
    font-weight: 600;
    color : #1A3552;
}

.tr-report-view-modal-subheading{
    font-size: 15px;
    font-weight: 500;
    color : #1A3552;
}

.tr-report-view-modal-close {
    height: 90px;
    font-size: 24px;
    padding-top: 40px;
    color : #656565;
}

.tr-report-view-modal-body {
    background-color: #F1F6F7;
    padding: 3%;
    width: 100%;
    box-sizing: border-box;
}

.tr-report-view-modal-help-text{
    border: 1px solid #555;
    color : #444;
    text-align: justify;
    padding: 8px;
    width: 100%;
    background-color: #fff;
    font-size: 14px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.tr-report-view-modal-rejection-text{
    border: 1px solid #555;
    color : #444;
    text-align: justify;
    padding: 8px;
    width: 100%;
    background-color: #fff;
    font-size: 14px;
    box-sizing: border-box;
    font-style: italic;
    margin-top: 15px;
    word-wrap: break-word;
}

.tr-report-view-modal-table-container{
    position: relative;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    margin-top: 15px;
    max-height: 500px;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.tr-report-view-modal-footer{
    background-color: #fff;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
}

.tr-report-view-modal-footer-button-container {
    display: flex;
    justify-content: flex-end;
    column-gap: 15px;
    align-items: center;
}

.tr-report-view-modal-footer-button {
    padding : 5px 20px 5px 20px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
}


.tr-report-view-save-button {
    background-color: #00BCB8;
    color : white;
    font-family: "Montserrat", sans-serif;
    border: 1px solid #00BCB8;
}

.tr-report-view-nil_transaction-label {
    position: absolute;
    background-color: #F5F5FD;
    color : rgb(33, 33, 33);
    font-family: "Montserrat", sans-serif;
    top : 50%;
    transform: translate(-50%, -50%);
    left : 50%;
    z-index: 1000;
    padding: 15px 25px 15px 25px;
    font-size: 14px;
}

.tr-report-view-broker-table-label{
    font-size: 14px;
    margin-top : 20px;
    font-weight: 600;
    padding-left: 3px;
}