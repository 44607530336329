
.send-confirmation-modal-wrapper{
    position: absolute;
    box-sizing: border-box;
    width: 55%;
    top: 9%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1001;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
}

.send-confirmation-modal-header{
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-left: 10px solid rgb(4,173,168);
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
}

.send-confirmation-modal-title-wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-top: 15px;
}

.send-confirmation-modal-heading{
    font-size: 21px;
    font-weight: 600;
    color : #1A3552;
}

.send-confirmation-modal-subheading{
    font-size: 13px;
    color : #1A3552;
}

.send-confirmation-modal-close {
    height: 90px;
    font-size: 24px;
    padding-top: 40px;
    color : #656565;
}

.send-confirmation-modal-body {
    background-color: #F1F6F7;
    padding: 3% 4% 3% 4%;
    width: 100%;
    box-sizing: border-box;
}

.send-confirmation-modal-footer{
    background-color: #fff;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
}

.send-confirmation-modal-footer-button-container {
    display: flex;
    justify-content: flex-end;
    column-gap: 15px;
    align-items: center;
}

.send-confirmation-modal-footer-button {
    padding : 5px 20px 5px 20px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
}

.send-confirmation-modal-footer-inactive-button {
    padding : 5px 20px 5px 20px;
    border-radius: 5px;
    font-size: 15px;
    cursor: not-allowed;
    background-color: #a9a9a9;
    color : rgb(112, 112, 112);
    font-family: "Montserrat", sans-serif;
    border: 1px solid rgb(112, 112, 112);
}

.save-button {
    background-color: #00BCB8;
    color : white;
    font-family: "Montserrat", sans-serif;
    border: 1px solid #00BCB8;
}

.close-button {
    background-color: #fff;
    color : #555;
    font-family: "Montserrat", sans-serif;
    border : 1px solid #444;
}

.content-container{
    background-color: #fff;
    padding: 2%;
}

.content-header{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.content-helptext{
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0px 10px 0px;
    padding: 5px;
    text-align: center;
}

.date-field-label{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
}

.date-picker{
    margin: 0px 0px 10px 0px;
    outline: none;
    padding: 0px;
}

.date-field-container{
    display: flex;
    flex-direction: column;
}

.content-date-bar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}