@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-datetime-picker__wrapper {
    border: thin solid #dbdbdb !important;
    background-color: #ffffff !important;
}

.react-datetime-picker__inputGroup {
  padding: 7px 13px !important;
}
/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.react-date-picker__wrapper {
  border: none ! important;
   color: #515151 ! important; 
}*/

/* extra from filechecker */

/*.react-date-picker__wrapper {
  border: none ! important;
  color: #515151 ! important;
}

.react-date-picker__inputGroup {
  color: #222222 ! important;
  padding-top: 5px;
}

.react-date-picker__inputGroup__input {
  color: #222222 ! important;
}

.cdate .react-date-picker__inputGroup {
  width: 100% ! important;
}*/

  .Table__itemCount {
    /* margin-top: 10px; */
    font-size: 14px;
  }
  
  .Table__pagination {
    display: flex;
    justify-content: flex-end;
    /*padding: 20px 10px;*/
  }
  
  .Table__pageButton {
    font-size: 15px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
  }
  
  .Table__pageButton--active {
    color: #45b3e3;
    font-weight: bold;
  }
  
  .ReactTable .rt-thead.-header {
    box-shadow: none;
    background: #F3F7FB;
  }
  
  .ReactTable .rt-thead .rt-th {
    border-right: none !important;
  }

  /** to change default padding for the status_colour */
  /*.react-table__status-colour {*/
  /*  padding: 2px 3px 2px 7px !important;*/
  /*}*/
  
  .ReactTable .rt-th {
    padding: 10px 0px 10px 10px !important;
  }
  
  .ReactTable .rt-tbody .rt-td {
    padding: 10px 0px 10px 10px !important;
    border-right: none !important;
  }
  
  .ReactTable .rt-tbody .rt-tr-group {
    border-bottom: none !important;
  }
  
  .ReactTable .rt-thead .rt-resizable-header-content {
    font-weight: 600;
    color: #464646;
  }
  .Table__itemCount {
    /* margin-top: 10px; */
    font-size: 14px;
  }
  
  .Table__pagination {
    display: flex;
    justify-content: flex-end;
    /*padding: 20px 10px;*/
  }
  
  .Table__pageButton {
    font-size: 15px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
  }
  
  .Table__pageButton--active {
    color: #45b3e3;
    font-weight: bold;
  }
  
  .ReactTable .rt-thead.-header {
    box-shadow: none;
    background: #F5F5FD;
  }

  /** to change default padding for the status_colour */
  .react-table__status-colour {
    padding: 2px 3px 2px 7px !important;
  }

.css-wsp0cs-MultiValueGeneric {
    font-weight: 600;
}
.Dropzone {
  /*height: 50px;
  width: 100%;*/
  background-color: #f7f7f7;
  /*border: 2px dashed rgb(187, 186, 186);*/
  /*border-radius: 50%;*/
  /*display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: column;*/
  font-size: 12px;
}

.Icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.FileInput {
  display: none ! important;
}
.Dropzone {
  /*height: 50px;
  width: 100%;*/
  background-color: #f7f7f7;
  /*border: 2px dashed rgb(187, 186, 186);*/
  /*border-radius: 50%;*/
  /*display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: column;*/
  font-size: 12px;
}

.Icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.FileInput {
  display: none ! important;
}
.broker-list-wrapper{
    position: absolute;
    box-sizing: border-box;
    width: 80%;
    top: 10%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 1001;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
}

.broker-list-header{
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-left: 10px solid rgb(4,173,168);
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.broker-list-heading{
    line-height: 90px;
    font-size: 20px;
    font-weight: bold;
    color : #1A3552;
}

.broker-list-close {
    height: 90px;
    font-size: 24px;
    padding-top: 40px;
    color : #656565;
}

.broker-list-body {
    background-color: #F1F6F7;
    padding: 2%;
    width: 100%;
    box-sizing: border-box;
}

.broker-list-button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.broker-list-label {
    color : #555;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: 4px;
}

.broker-list-row {
    margin-bottom: 15px;
}

.broker-list-add-button {
    padding: 8px 15px 8px 15px;
    border-radius: 5px;
    background-color: #223B5A;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
}

.broker-list-main-ellipsis {
    display: inline-block;
    width: 15px;
    text-align: center;
    font-size: 16px;
    position: relative;
}

.broker-list-main-context-menu{
    position: absolute;
    top : 10px;
    right: 10px;
    background-color: #fff;
    padding: 5px;
    box-shadow: 0px 0px 2px #555;
    z-index: 100;
}
.broker-list-main-context-menu-item{
    font-size: 14px;
    padding: 3px 8px 3px 8px;
    cursor: pointer;
}

.broker-list-main-context-menu-item:hover {
    background-color: #bbbbbb;
}

.broker-list-table-container {
    margin-top : 15px;
}

.broker-list-footer-button-container {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
    align-items: center;
    margin-top: 15px;
}

.broker-list-footer-button {
    padding : 5px 20px 5px 20px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
}

.broker-list-save-button {
    background-color: #00BCB8;
    color : white;
    font-family: "Montserrat", sans-serif;
    border: 1px solid #00BCB8;
}

.broker-list-close-button {
    background-color: #fff;
    color : #555;
    font-family: "Montserrat", sans-serif;
    border : 1px solid #444;
}

.broker-table-row-context-menu{
    position : absolute;
    background-color: #fff;
    padding: 5px 0px;
    display: inline-block;
    width : 75px;
    right: 15px;
    box-shadow: 0px 0px 4px rgba(0,0,0,0.4);
    z-index: 10000;
}

.interactive-overlay-broker-modal{
    position: absolute;
    top : -140px;
    left: -170px;
    width: 84vw;
    height: 150vh;
    background-color: rgba(255,255,255,0.5);
}


.add-new-broker-modal-wrapper{
    position: absolute;
    box-sizing: border-box;
    width: 50%;
    top: 8%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 1001;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
}

.add-new-broker-modal-header{
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-left: 10px solid rgb(4,173,168);
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
}

.add-new-broker-modal-title-wrapper{
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
    padding-top: 15px;
}

.add-new-broker-modal-heading{
    font-size: 20px;
    font-weight: bold;
    color : #1A3552;
}

.add-new-broker-modal-subheading{
    font-size: 16px;
    color : #1A3552;
}

.add-new-broker-modal-close {
    height: 90px;
    font-size: 24px;
    padding-top: 40px;
    color : #656565;
}

.add-new-broker-modal-body {
    background-color: #F1F6F7;
    padding: 3% 4% 3% 4%;
    width: 100%;
    box-sizing: border-box;
}

.add-new-broker-modal-footer{
    background-color: #fff;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
}

.add-new-broker-modal-footer-button-container {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
    align-items: center;
}

.add-new-broker-modal-footer-button {
    padding : 5px 20px 5px 20px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
}

.save-button {
    background-color: #00BCB8;
    color : white;
    font-family: "Montserrat", sans-serif;
    border: 1px solid #00BCB8;
}

.close-button {
    background-color: #fff;
    color : #555;
    font-family: "Montserrat", sans-serif;
    border : 1px solid #444;
}

.label{
    color : #555;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    padding-left: 5px;
}

.text-input {
    margin-bottom: 20px;
    height: 38px;
    line-height: 38px;
    border: 1px solid #ddd;
    font-size: 13px;
    border-radius: 5px;
    padding: 0px 10px 0px 10px;
    width: 100%;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    background-color: #fff;
}

.date-picker {
    background-color: #fff;
    padding : 5px;
    border: 1px solid #ddd;
    font-size: 13px;
    border-radius: 5px;
    margin-bottom: 17px;
}

.toggle-container{
    background-color: #fff;
    padding: 3px 2px 0 3px;
    border-radius: 10px;
    display: inline-block;
    margin-left: 5px;
}

.mandatory-asterisk{
    color: red;
    margin-left: 5px;
    font-size: 20px;
}
.inactive-overlay{
    position:absolute;
    top : 0;
    bottom: 0;
    width: 98%;
    height: 100%;
    background-color: rgba(255,255,255,0.5);
    z-index: 600;
}

.error-bar{
    background-color: #f6dbdb;
    color: #de8d8e;
    font-weight: 600;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.error-msg-div{
    font-size: 16px;
    display: inline;
}

.error-bar-close{
    font-size: "16px"; 
    cursor: "pointer";
    display: inline;
}

.container{
    width: 98%;
    margin : auto;
    margin-top : 15px;
    border: 1px solid #eee;
    border-radius: 3px 3px 0px 0px;
    box-sizing: border-box;
}

.header-container{
    box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    padding: 5px 10px 10px 10px;
    border-radius: 3px 3px 0px 0px;
}

.header-text-container{
    padding: 10px 0px 0px 10px;
    font-weight: 600;
    font-size: 14px;
    color : #555;
}

.pill-bar{
    box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    padding: 5px 10px 10px 10px;
    border-radius: 3px 3px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.pill-container{
    display: flex;
    flex-direction: row;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    align-items: center;
    padding: 5px 0px 5px 0px;
}

.pill{
    position: relative;
    min-width: 150px;
    border-radius: 15px;
    border: 1px solid #1E3E62;
    font-size: 13px;
    cursor: pointer;
}

.pill-active{
    background-color: #1E3E62;
    color : #ffffff;
    
}

.pill-inactive{
    background-color: #EEF2F7;
    color: #1E3E62;
}

.pill-text-container{
    width: 100%;
    padding: 5px;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
}
.pill-count-container{
    position: absolute;
    right : 5px;
    top : 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    cursor: pointer;
}

.pill-tick-container{
    position: absolute;
    left : 5px;
    top : 50%;
    -webkit-transform: translateY(-40%);
            transform: translateY(-40%);
    cursor: pointer;
    font-size: 11px;
}

.main-ellipsis {
    width: 15px;
    text-align: center;
    font-size: 16px;
    position: relative;
    cursor: pointer;
}

.main-context-menu{
    position: absolute;
    top : 10px;
    right: 10px;
    background-color: #fff;
    padding: 5px;
    box-shadow: 0px 0px 2px #555;
    z-index: 100;
}
.main-context-menu-item{
    font-size: 14px;
    padding: 3px 8px 3px 8px;
    cursor: pointer;
}

.main-context-menu-item:hover {
    background-color: #eeeeee;
}

.option-bar{
    box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    padding: 0px 10px 10px 15px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 25px;
}

.table-container{
    position: relative;
}

.multi-select-option-link{
    font-style: italic;
    color : #127AC4;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
}

.select-all-container{
    position : absolute;
    top : 55px;
    left : 11px;
    cursor: pointer;
    z-index: 500;
}

.option-bar-bottom{
    box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    padding: 15px 10px 15px 15px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 35px;
}

.send-confirmation-modal-wrapper{
    position: absolute;
    box-sizing: border-box;
    width: 55%;
    top: 9%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 1001;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
}

.send-confirmation-modal-header{
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-left: 10px solid rgb(4,173,168);
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
}

.send-confirmation-modal-title-wrapper{
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
    padding-top: 15px;
}

.send-confirmation-modal-heading{
    font-size: 21px;
    font-weight: 600;
    color : #1A3552;
}

.send-confirmation-modal-subheading{
    font-size: 13px;
    color : #1A3552;
}

.send-confirmation-modal-close {
    height: 90px;
    font-size: 24px;
    padding-top: 40px;
    color : #656565;
}

.send-confirmation-modal-body {
    background-color: #F1F6F7;
    padding: 3% 4% 3% 4%;
    width: 100%;
    box-sizing: border-box;
}

.send-confirmation-modal-footer{
    background-color: #fff;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
}

.send-confirmation-modal-footer-button-container {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
    align-items: center;
}

.send-confirmation-modal-footer-button {
    padding : 5px 20px 5px 20px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
}

.send-confirmation-modal-footer-inactive-button {
    padding : 5px 20px 5px 20px;
    border-radius: 5px;
    font-size: 15px;
    cursor: not-allowed;
    background-color: #a9a9a9;
    color : rgb(112, 112, 112);
    font-family: "Montserrat", sans-serif;
    border: 1px solid rgb(112, 112, 112);
}

.save-button {
    background-color: #00BCB8;
    color : white;
    font-family: "Montserrat", sans-serif;
    border: 1px solid #00BCB8;
}

.close-button {
    background-color: #fff;
    color : #555;
    font-family: "Montserrat", sans-serif;
    border : 1px solid #444;
}

.content-container{
    background-color: #fff;
    padding: 2%;
}

.content-header{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.content-helptext{
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0px 10px 0px;
    padding: 5px;
    text-align: center;
}

.date-field-label{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
}

.date-picker{
    margin: 0px 0px 10px 0px;
    outline: none;
    padding: 0px;
}

.date-field-container{
    display: flex;
    flex-direction: column;
}

.content-date-bar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.confirmation-report-modal-wrapper{
    position: absolute;
    box-sizing: border-box;
    width: 90%;
    top: 5%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 1001;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
}

.confirmation-report-modal-header{
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-left: 10px solid rgb(4,173,168);
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
}

.confirmation-report-modal-title-wrapper{
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
    padding-top: 15px;
}

.confirmation-report-modal-heading{
    font-size: 21px;
    font-weight: 600;
    color : #1A3552;
}

.confirmation-report-modal-subheading{
    font-size: 15px;
    font-weight: 500;
    color : #1A3552;
}

.confirmation-report-modal-close {
    height: 90px;
    font-size: 24px;
    padding-top: 40px;
    color : #656565;
}

.confirmation-report-modal-body {
    background-color: #F1F6F7;
    padding: 3%;
    width: 100%;
    box-sizing: border-box;
}

.confirmation-report-modal-help-text{
    border: 1px solid #555;
    color : #444;
    text-align: justify;
    padding: 8px;
    width: 100%;
    background-color: #fff;
    font-size: 14px;
    box-sizing: border-box;
}

.confirmation-report-modal-table-container{
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    margin-top: 15px;
    max-height: 500px;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.confirmation-report-modal-confirmation-text-container{
    width: 100%;
    box-sizing: border-box;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
}

.confirmation-report-modal-confirmation-checkbox{
    box-sizing: border-box;
    font-size: 18px;
}

.confirmation-report-modal-confirmation-text{
    box-sizing: border-box;
    display: inline-block;
    color : #444;
    font-size: 14px;
    text-align: justify;
}

.confirmation-report-modal-footer{
    background-color: #fff;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
}

.confirmation-report-modal-footer-button-container {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
    align-items: center;
}

.confirmation-report-modal-footer-button-subcontainer {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 3px;
    -webkit-column-gap: 3px;
            column-gap: 3px;
    align-items: center;
}

.confirmation-report-modal-footer-button {
    padding : 5px 20px 5px 20px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
}

.confirmation-report-modal-footer-inactive-button {
    padding : 5px 20px 5px 20px;
    border-radius: 5px;
    font-size: 15px;
    cursor: not-allowed;
    background-color: #ddd;
    color : rgb(255, 255, 255);
    font-family: "Montserrat", sans-serif;
    border: 1px solid #ddd;
}

.confirmation-report-save-button {
    background-color: #00BCB8;
    color : white;
    font-family: "Montserrat", sans-serif;
    border: 1px solid #00BCB8;
}

.confirmation-report-reject-button {
    background-color: #203864;
    color : white;
    font-family: "Montserrat", sans-serif;
    border: 1px solid #203864;
}

.confirmation-report-close-button {
    background-color: #fff;
    color : #333;
    font-family: "Montserrat", sans-serif;
    border : 1px solid #bbb;
}
.tr-rejection-cause-modal-overlay{
    position: fixed;
    top : 0;
    left : 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.3);
    z-index: 100001;
}

.tr-rejection-modal-wrapper{
    position: absolute;
    box-sizing: border-box;
    width: 60%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    z-index: 1001;
    font-family: "Montserrat";
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
    border: 1px solid black;
}

.tr-rejection-modal-header{
    position: relative;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-left: 10px solid rgb(4,173,168);
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
}

.tr-rejection-modal-title-wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
    padding-top: 15px;
}

.tr-rejection-modal-heading{
    font-size: 21px;
    font-weight: 600;
    color : #1A3552;
    margin-right: 30px;
}

.tr-rejection-modal-subheading{
    font-size: 15px;
    font-weight: 500;
    color : #1A3552;
    margin-right: 30px;
}

.tr-rejection-modal-close {
    position: absolute;
    top : 15px;
    right : 15px;
    font-size: 24px;
    color : #656565;
}

.tr-rejection-modal-body {
    position: relative;
    background-color: #F1F6F7;
    padding: 3%;
    width: 100%;
    box-sizing: border-box;
}

.tr-rejection-modal-help-text{
    border: 1px solid #555;
    color : #444;
    text-align: justify;
    padding: 8px;
    width: 100%;
    background-color: #fff;
    font-size: 14px;
    box-sizing: border-box;
}

.tr-rejection-modal-label{
    color : #222;
    text-align: justify;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;
}

.tr-rejection-modal-cause-textarea{
    color : #222;
    text-align: justify;
    padding: 8px;
    width: 100%;
    font-size: 14px;
    resize: none;
    overflow: hidden;
    min-height: 50px;
    max-height: 200px;
    box-sizing: border-box;
    margin-top: 15px;
    scroll-behavior : smooth;
    overflow-y : auto;
    font-family: 'Montserrat';
    outline: none;
}

.tr-rejection-modal-table-container{
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    margin-top: 15px;
}

.tr-rejection-modal-confirmation-text-container{
    width: 100%;
    box-sizing: border-box;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
}

.tr-rejection-modal-confirmation-checkbox{
    box-sizing: border-box;
    font-size: 18px;
}

.tr-rejection-modal-confirmation-text{
    box-sizing: border-box;
    display: inline-block;
    color : #444;
    font-size: 14px;
    text-align: justify;
}

.tr-rejection-modal-footer{
    position: relative;
    background-color: #fff;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
}

.tr-rejection-modal-footer-button-container {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
    align-items: center;
}

.tr-rejection-modal-footer-button-subcontainer {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 3px;
    -webkit-column-gap: 3px;
            column-gap: 3px;
    align-items: center;
}

.tr-rejection-modal-footer-button {
    padding : 5px 20px 5px 20px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
}

.tr-rejection-modal-footer-inactive-button {
    padding : 5px 20px 5px 20px;
    border-radius: 5px;
    font-size: 15px;
    cursor: not-allowed;
    background-color: #ddd;
    color : rgb(255, 255, 255);
    font-family: "Montserrat", sans-serif;
    border: 1px solid #ddd;
}

.tr-rejection-save-button {
    background-color: #00BCB8;
    color : white;
    font-family: "Montserrat", sans-serif;
    border: 1px solid #00BCB8;
}

.tr-rejection-reject-button {
    background-color: #203864;
    color : white;
    font-family: "Montserrat", sans-serif;
    border: 1px solid #203864;
}

.tr-rejection-close-button {
    background-color: #fff;
    color : #333;
    font-family: "Montserrat", sans-serif;
    border : 1px solid #bbb;
}

.tr-report-view-modal-wrapper{
    position: absolute;
    box-sizing: border-box;
    width: 90%;
    top: 5%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 1001;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
}

.tr-report-view-modal-header{
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-left: 10px solid rgb(4,173,168);
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
}

.tr-report-view-modal-title-wrapper{
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
    padding-top: 15px;
}

.tr-report-view-modal-heading{
    font-size: 21px;
    font-weight: 600;
    color : #1A3552;
}

.tr-report-view-modal-subheading{
    font-size: 15px;
    font-weight: 500;
    color : #1A3552;
}

.tr-report-view-modal-close {
    height: 90px;
    font-size: 24px;
    padding-top: 40px;
    color : #656565;
}

.tr-report-view-modal-body {
    background-color: #F1F6F7;
    padding: 3%;
    width: 100%;
    box-sizing: border-box;
}

.tr-report-view-modal-help-text{
    border: 1px solid #555;
    color : #444;
    text-align: justify;
    padding: 8px;
    width: 100%;
    background-color: #fff;
    font-size: 14px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.tr-report-view-modal-rejection-text{
    border: 1px solid #555;
    color : #444;
    text-align: justify;
    padding: 8px;
    width: 100%;
    background-color: #fff;
    font-size: 14px;
    box-sizing: border-box;
    font-style: italic;
    margin-top: 15px;
    word-wrap: break-word;
}

.tr-report-view-modal-table-container{
    position: relative;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    margin-top: 15px;
    max-height: 500px;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.tr-report-view-modal-footer{
    background-color: #fff;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
}

.tr-report-view-modal-footer-button-container {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
    align-items: center;
}

.tr-report-view-modal-footer-button {
    padding : 5px 20px 5px 20px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
}


.tr-report-view-save-button {
    background-color: #00BCB8;
    color : white;
    font-family: "Montserrat", sans-serif;
    border: 1px solid #00BCB8;
}

.tr-report-view-nil_transaction-label {
    position: absolute;
    background-color: #F5F5FD;
    color : rgb(33, 33, 33);
    font-family: "Montserrat", sans-serif;
    top : 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    left : 50%;
    z-index: 1000;
    padding: 15px 25px 15px 25px;
    font-size: 14px;
}

.tr-report-view-broker-table-label{
    font-size: 14px;
    margin-top : 20px;
    font-weight: 600;
    padding-left: 3px;
}
.report-link-wrapper{
    display: inline-block;
    margin-left : 8px;
}

.report-link{
    display: flex;
    align-items: center;
    text-decoration: none;
}

.report-link-text{
    text-decoration: none;
    font-weight: bold;
    font-style: italic;
    color : rgb(43, 120, 236);
    font-size: 14px;
}

.report-link-icon{
    margin-left: 6px;
    -webkit-filter: invert(20%) sepia(84%) saturate(4841%) hue-rotate(341deg) brightness(108%) contrast(113%);
            filter: invert(20%) sepia(84%) saturate(4841%) hue-rotate(341deg) brightness(108%) contrast(113%);
}
