.broker-list-wrapper{
    position: absolute;
    box-sizing: border-box;
    width: 80%;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1001;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
}

.broker-list-header{
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-left: 10px solid rgb(4,173,168);
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.broker-list-heading{
    line-height: 90px;
    font-size: 20px;
    font-weight: bold;
    color : #1A3552;
}

.broker-list-close {
    height: 90px;
    font-size: 24px;
    padding-top: 40px;
    color : #656565;
}

.broker-list-body {
    background-color: #F1F6F7;
    padding: 2%;
    width: 100%;
    box-sizing: border-box;
}

.broker-list-button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.broker-list-label {
    color : #555;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: 4px;
}

.broker-list-row {
    margin-bottom: 15px;
}

.broker-list-add-button {
    padding: 8px 15px 8px 15px;
    border-radius: 5px;
    background-color: #223B5A;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
}

.broker-list-main-ellipsis {
    display: inline-block;
    width: 15px;
    text-align: center;
    font-size: 16px;
    position: relative;
}

.broker-list-main-context-menu{
    position: absolute;
    top : 10px;
    right: 10px;
    background-color: #fff;
    padding: 5px;
    box-shadow: 0px 0px 2px #555;
    z-index: 100;
}
.broker-list-main-context-menu-item{
    font-size: 14px;
    padding: 3px 8px 3px 8px;
    cursor: pointer;
}

.broker-list-main-context-menu-item:hover {
    background-color: #bbbbbb;
}

.broker-list-table-container {
    margin-top : 15px;
}

.broker-list-footer-button-container {
    display: flex;
    justify-content: flex-end;
    column-gap: 15px;
    align-items: center;
    margin-top: 15px;
}

.broker-list-footer-button {
    padding : 5px 20px 5px 20px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
}

.broker-list-save-button {
    background-color: #00BCB8;
    color : white;
    font-family: "Montserrat", sans-serif;
    border: 1px solid #00BCB8;
}

.broker-list-close-button {
    background-color: #fff;
    color : #555;
    font-family: "Montserrat", sans-serif;
    border : 1px solid #444;
}

.broker-table-row-context-menu{
    position : absolute;
    background-color: #fff;
    padding: 5px 0px;
    display: inline-block;
    width : 75px;
    right: 15px;
    box-shadow: 0px 0px 4px rgba(0,0,0,0.4);
    z-index: 10000;
}

.interactive-overlay-broker-modal{
    position: absolute;
    top : -140px;
    left: -170px;
    width: 84vw;
    height: 150vh;
    background-color: rgba(255,255,255,0.5);
}

