
.confirmation-report-modal-wrapper{
    position: absolute;
    box-sizing: border-box;
    width: 90%;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1001;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
}

.confirmation-report-modal-header{
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-left: 10px solid rgb(4,173,168);
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
}

.confirmation-report-modal-title-wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-top: 15px;
}

.confirmation-report-modal-heading{
    font-size: 21px;
    font-weight: 600;
    color : #1A3552;
}

.confirmation-report-modal-subheading{
    font-size: 15px;
    font-weight: 500;
    color : #1A3552;
}

.confirmation-report-modal-close {
    height: 90px;
    font-size: 24px;
    padding-top: 40px;
    color : #656565;
}

.confirmation-report-modal-body {
    background-color: #F1F6F7;
    padding: 3%;
    width: 100%;
    box-sizing: border-box;
}

.confirmation-report-modal-help-text{
    border: 1px solid #555;
    color : #444;
    text-align: justify;
    padding: 8px;
    width: 100%;
    background-color: #fff;
    font-size: 14px;
    box-sizing: border-box;
}

.confirmation-report-modal-table-container{
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    margin-top: 15px;
    max-height: 500px;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.confirmation-report-modal-confirmation-text-container{
    width: 100%;
    box-sizing: border-box;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 20px;
}

.confirmation-report-modal-confirmation-checkbox{
    box-sizing: border-box;
    font-size: 18px;
}

.confirmation-report-modal-confirmation-text{
    box-sizing: border-box;
    display: inline-block;
    color : #444;
    font-size: 14px;
    text-align: justify;
}

.confirmation-report-modal-footer{
    background-color: #fff;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
}

.confirmation-report-modal-footer-button-container {
    display: flex;
    justify-content: flex-end;
    column-gap: 15px;
    align-items: center;
}

.confirmation-report-modal-footer-button-subcontainer {
    display: flex;
    justify-content: flex-end;
    column-gap: 3px;
    align-items: center;
}

.confirmation-report-modal-footer-button {
    padding : 5px 20px 5px 20px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
}

.confirmation-report-modal-footer-inactive-button {
    padding : 5px 20px 5px 20px;
    border-radius: 5px;
    font-size: 15px;
    cursor: not-allowed;
    background-color: #ddd;
    color : rgb(255, 255, 255);
    font-family: "Montserrat", sans-serif;
    border: 1px solid #ddd;
}

.confirmation-report-save-button {
    background-color: #00BCB8;
    color : white;
    font-family: "Montserrat", sans-serif;
    border: 1px solid #00BCB8;
}

.confirmation-report-reject-button {
    background-color: #203864;
    color : white;
    font-family: "Montserrat", sans-serif;
    border: 1px solid #203864;
}

.confirmation-report-close-button {
    background-color: #fff;
    color : #333;
    font-family: "Montserrat", sans-serif;
    border : 1px solid #bbb;
}